<template>
  <view-item-setting>
    <flex-box>
      <template #default="{ height }">
        <en-scrollbar :height="height" view-class="border border-solid border-gray-300">
          <en-tree :data="tree.data" :loading="tree.loading" :props="{ label: 'name' }" node-key="id" :expand-on-click-node="false">
            <template #default="{ node, data }: { node: any; data: EnocloudAdminDefinitions['BranchDto'] }">
              <div class="w-full flex items-center gap-6">
                <span>{{ node.label }}</span>
                <div class="">
                  <en-button link type="primary" @click.capture="tree.operation.edit.click(data)">编辑</en-button>
                  <en-button link type="primary" @click="tree.operation.append.click(data)">添加子连锁店</en-button>
                </div>
              </div>
            </template>
          </en-tree>
        </en-scrollbar>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑子连锁店' : '添加子连锁店'">
    <en-form :model="detail.form.data" :rules="detail.form.rules" :ref="detail.form.ref">
      <en-form-item label="父连锁店">
        <en-input :value="(detail.form.data.parent && detail.form.data.parent.name) || ''" disabled></en-input>
      </en-form-item>
      <en-form-item label="连锁店名称" prop="name">
        <en-input v-model="detail.form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="连锁店简称" prop="shortName">
        <en-input v-model="detail.form.data.shortName" @change="detail.form.shortName.change"></en-input>
      </en-form-item>
      <en-form-item label="连锁店编号" prop="code">
        <en-input v-model="detail.form.data.code" @change="detail.form.code.change"></en-input>
      </en-form-item>
      <en-form-item label="备注" prop="comment">
        <en-input v-model="detail.form.data.comment" type="textarea" maxlength="200" show-word-limit></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="detail.footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      tree: {
        active: {} as EnocloudAdminDefinitions['BranchDto'],
        ajax: {
          get: {
            action: 'GET /enocloud/admin/branch',
            data: 'array',
            loading: true
          }
        },
        children: {
          operation: {
            edit: {
              click(row: EnocloudAdminDefinitions['BranchDto']) {
                this.detail.form.init()
                this.detail.form.data.id = row.id
                this.detail.form.get()
                this.detail.visible = true
              }
            },
            append: {
              click(row: EnocloudAdminDefinitions['BranchDto']) {
                this.detail.form.init()
                this.detail.form.data.parent = row
                this.detail.visible = true
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            rules: {
              name: [{ required: true, message: '请输入连锁店名称', trigger: 'blur' }],
              shortName: [{ required: true, message: '请输入连锁店简称', trigger: 'blur' }],
              code: [{ required: true, message: '请输入连锁店简称', trigger: 'blur' }]
            },
            ajax: {
              get: {
                action: 'GET /enocloud/admin/branch/:branchId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/admin/branch',
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/admin/branch',
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            children: {
              shortName: {
                change(value: string) {
                  this.detail.form.data.shortName = value.substring(0, 10)
                }
              },
              code: {
                change(value: string) {
                  this.detail.form.data.code = value.toUpperCase()
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                this.tree.get()
                this.detail.visible = false
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.tree.get()
  }
})
</script>
